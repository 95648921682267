import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import "./pharmacy-refills.scss";
import { useDispatch, useSelector } from "react-redux";
import { SavePharmacyRefills } from "redux/actions";
import { getLocalStorageValue } from "config/helper";

const PharmacyRefills = () => {
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const breadcrumbList = [{ lable: "Pharmacy Refills Form", link: "" }];
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [submissionMessage, setSubmissionMessage] = useState("");

  const { your_recaptcha_site_key } = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

  const { saveRefillsLoading, saveRefillsError } = useSelector(
    ({ pharmacy = {} }) => pharmacy
  );
  console.log("saveRefillsError", saveRefillsError?.status);
  const clientId = getLocalStorageValue("RSAclient-id");
  const storeId = getLocalStorageValue("selected-store-id");
  const [formData, setFormData] = useState({
    selected_store_location: "99",
    first_name: "",
    last_name: "",
    phone_number: "",
    email_id: "",
    message: "",
    prescriptionNumbers: [""],
  });

  const [formErrors, setFormErrors] = useState({});

  const onChangeReCaptcha = (value) => {
    setRecaptchaValue(value);
    setCaptchaError("");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePrescriptionChange = (index, value) => {
    const updatedNumbers = [...formData.prescriptionNumbers];
    updatedNumbers[index] = value;
    setFormData({ ...formData, prescriptionNumbers: updatedNumbers });
  };

  const handleAddPrescription = () => {
    setFormData({
      ...formData,
      prescriptionNumbers: [...formData.prescriptionNumbers, ""],
    });
  };

  const handleRemovePrescription = (index) => {
    const updatedNumbers = [...formData.prescriptionNumbers];
    updatedNumbers.splice(index, 1);
    setFormData({ ...formData, prescriptionNumbers: updatedNumbers });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.first_name) errors.first_name = "First name is required.";
    if (!formData.last_name) errors.last_name = "Last name is required.";
    if (!formData.phone_number || formData.phone_number.length < 10)
      errors.phone_number = "Valid phone number is required.";
    if (!formData.email_id) errors.email_id = "Email is required.";
    if (recaptchaValue === "") errors.recaptcha = "The captcha field is required.";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const filteredFormData = {
      ...formData,
      prescriptionNumbers: formData.prescriptionNumbers.filter((num) => num.trim() !== ""),
    };

    try {
      const payload = {
        RSAClientId: clientId,
        ClientStoreId: storeId,
        selected_store_location: formData.selected_store_location,
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone_number: formData.phone_number,
        message: formData.message,
        email_id: formData.email_id,
        prescription_number_list: formData.prescriptionNumbers.filter((num) => num.trim() !== ""),
      };

      dispatch(SavePharmacyRefills(payload)); // Dispatch the action with the payload

      // Reset form to default values
      setFormData({
        selected_store_location: "99",
        first_name: "",
        last_name: "",
        phone_number: "",
        email_id: "",
        message: "",
        prescriptionNumbers: [""],
      });
      setRecaptchaValue("");
      setCaptchaError("");
      setFormErrors({});
      setSubmissionMessage("Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmissionMessage("Failed to submit the form. Please try again.");
    }
  };

  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title="Subscriber to White's Perks"
      />
      <div className="pharmacy-section">
        <div className="container">
          <div className="title-wrap">
            <h1>Pharmacy Refills</h1>
          </div>

          <p>
            Please fill out the pharmacy refill form below and submit to have
            your order ready by <span>2 PM tomorrow.</span>
          </p>
          <p>
            Do you have a Harding's Pharmacy Bonus Card? Each time you fill a
            prescription at our pharmacy, we will punch your card. After 12
            punches, redeem your completed card at the grocery checkout for $10
            off your next grocery purchase of $10 or more!
          </p>
          <div className="contact-content">
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                <label className="form-label">
                  Location<span>&nbsp;*</span>
                </label>
                <select
                  name="selected_store_location"
                  className="input-label"
                  value={formData.selected_store_location}
                  onChange={handleInputChange}
                >
                  <option value="99">Fresh Value 4th Avenue</option>
                  <option value="100">Fresh Value Alabama City</option>
                  <option value="87">Fresh Value Athens</option>
                </select>
              </div>
              <div className="form-main">
                <label className="form-label">
                  Names<span>&nbsp;*</span>
                </label>
                <div className="form-wrapper-main">
                  <div className="form-wrapper-wrap">
                    <input
                      type="text"
                      name="first_name"
                      required
                      placeholder="First Name"
                      className="input-label"
                      value={formData.first_name}
                      onChange={handleInputChange}
                    />
                    {formErrors.first_name && (
                      <span className="error-message">
                        {formErrors.first_name}
                      </span>
                    )}
                  </div>
                  <div className="form-wrapper-wrap">
                    <input
                      type="text"
                      name="last_name"
                      required
                      placeholder="Last Name"
                      className="input-label"
                      value={formData.last_name}
                      onChange={handleInputChange}
                    />
                    {formErrors.last_name && (
                      <span className="error-message">
                        {formErrors.last_name}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-main">
                <div className="form-wrapper-main">
                  <div className="form-wrapper-wrap">
                    <label className="form-label">
                      Phone<span>&nbsp;*</span>
                    </label>
                    <input
                      type="text"
                      name="phone_number"
                      maxLength="10"
                      required
                      placeholder="Phone Number"
                      className="input-label"
                      value={formData.phone_number}
                      onChange={handleInputChange}
                    />
                    {formErrors.phone_number && (
                      <span className="error-message">
                        {formErrors.phone_number}
                      </span>
                    )}
                  </div>
                  <div className="form-wrapper-wrap">
                    <label className="form-label">
                      Email<span>&nbsp;*</span>
                    </label>
                    <input
                      type="email"
                      name="email_id"
                      required
                      placeholder="Email"
                      className="input-label"
                      value={formData.email_id}
                      onChange={handleInputChange}
                    />
                    {formErrors.email_id && (
                      <span className="error-message">
                        {formErrors.email_id}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-number-wrap">
                <div className="form-wrapper">
                  <label className="form-label">
                    Prescription Number<span>&nbsp;*</span>
                  </label>
                  {formData.prescriptionNumbers.map((prescription, index) => (
                    <div className="prescription-nub-wrap" key={index}>
                      <input
                        type="text"
                        name={`prescription_number_${index}`}
                        maxLength="10"
                        required
                        placeholder="Prescription Number"
                        className="input-label"
                        value={prescription}
                        onChange={(e) =>
                          handlePrescriptionChange(index, e.target.value)
                        }
                      />
                      {formData.prescriptionNumbers.length < 5 && (
                        <div
                          className="add-iocn"
                          onClick={handleAddPrescription}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill="currentColor"
                              d="M8 15c-3.86 0-7-3.14-7-7s3.14-7 7-7s7 3.14 7 7s-3.14-7-7-7M8 2C4.69 2 2 4.69 2 8s2.69 6 6 6s6-2.69 6-6s-2.69-6-6-6"
                            />
                            <path
                              fill="currentColor"
                              d="M8 11.5c-.28 0-.5-.22-.5-.5V5c0-.28.22-.5.5-.5s.5.22.5.5v6c0 .28-.22.5-.5.5"
                            />
                            <path
                              fill="currentColor"
                              d="M11 8.5H5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h6c.28 0 .5.22.5.5s-.22.5-.5.5"
                            />
                          </svg>
                        </div>
                      )}
                      {formData.prescriptionNumbers.length > 1 && (
                        <div
                          className="minus-iocn"
                          onClick={() => handleRemovePrescription(index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill="none"
                              stroke="currentColor"
                              d="M4 8h8m2.5 0a6.5 6.5 0 1 1-13 0a6.5 6.5 0 0 1 13 0Z"
                              strokeWidth="1"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="form-wrapper">
                <label className="form-label" htmlFor="message">
                  Message
                </label>
                <textarea
                  className="form-control involved-message"
                  name="message"
                  id="message"
                  cols="30"
                  rows="5"
                  placeholder="Enter Your Message"
                  value={formData.message}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <Link to="/ApproveScreen" className="badge btn-danger mr-40">
                Click here to view our privacy practices
              </Link>
              <div className="re-captcha">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={your_recaptcha_site_key}
                  onChange={onChangeReCaptcha}
                />
                {formErrors.recaptcha && (
                  <span className="error-message">{formErrors.recaptcha}</span>
                )}
              </div>
              <div className="form-wrapper">
                <button type="submit" className="signup-btn">
                  {saveRefillsLoading ? "Saving" : "Submit"}
                </button>
              </div>
              {!saveRefillsLoading && saveRefillsError?.status && (
                <div className={`submission-message ${saveRefillsError.status === "success" ? "success" : "error"}`}>
                  {saveRefillsError.message}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PharmacyRefills;