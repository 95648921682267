import { SAVE_PHARMACY_REFILLS, 
    SAVE_PHARMACY_REFILLS_FAILURE, 
    SAVE_PHARMACY_REFILLS_SUCCESS } from 'redux/constants/actionTypes';

    const initialState = {
    saveRefillsLoading: false,
    saveRefillsError: '',
};

const pharmacyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_PHARMACY_REFILLS:
            return {
                ...state,
                saveRefillsLoading: true,
            };
        case SAVE_PHARMACY_REFILLS_SUCCESS:
            return {
                ...state,
                saveRefillsLoading: false,
                saveRefillsError: action.payload,
            };
        case SAVE_PHARMACY_REFILLS_FAILURE:
            return {
                ...state,
                saveRefillsLoading: false,
                saveRefillsError: action.payload.error,
            };
        default:
            return state;
    }
};

export default pharmacyReducer;
