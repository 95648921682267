import {
    SAVE_PHARMACY_REFILLS,
    SAVE_PHARMACY_REFILLS_SUCCESS,
    SAVE_PHARMACY_REFILLS_FAILURE
  } from "redux/constants/actionTypes";
  import axios from "config/axios";

export const savePharmacyRefills = (payload) => ({
    type: SAVE_PHARMACY_REFILLS,
    payload: payload,
});
export const savePharmacyRefillsSuccess = (payload) => {
  return {
    type: SAVE_PHARMACY_REFILLS_SUCCESS,
    payload: payload,
  };
};

export const savePharmacyRefillsFailure = (payload) => {
  return {
    type: SAVE_PHARMACY_REFILLS_FAILURE,
    payload: payload,
  };
};
export const SavePharmacyRefills = (body) => async (dispatch) => {
    dispatch(savePharmacyRefills());
    axios
      .post("/AogRXRefillsForm", body)
      .then((res) => {
        dispatch(savePharmacyRefillsSuccess(res.data));
      })
      .catch((error) => {
        dispatch(
            savePharmacyRefillsFailure({ error: error.response.data.message })
        );
      });
  };